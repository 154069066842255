<template>
  <div>
    <v-row class="mx-n1" style="border-bottom: 1px solid lightgrey">
      <v-col>Date/Time</v-col>
      <v-col>
        <div class="d-flex">
          <div>Sold</div>
          <v-spacer/>
          <div style="text-align:right">Remaining</div>
        </div>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-row>
      <v-col class="clickableRow">
        <v-row class="mx-n1 underlinedRow" v-for="(eventTime, i) in eventTimes" :key="i">
          <v-col @click="selectItem(eventTime)">{{utils.dateToNiceString(eventTime.start)}}</v-col>
          <v-col @click="selectItem(eventTime)">
            <div class="d-flex">
              <div>{{eventTime.soldTickets}}</div>
              <v-spacer/>
              <div style="text-align:right">{{eventTime.remainingTickets}}</div>
            </div>
            <v-progress-linear height="10" rounded color="success" :value="percent(eventTime)"/>
          </v-col>
          <v-col cols="1">
            <v-tooltip right v-if="!eventTime.soldTickets">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="error" @click="$emit('deleteEventTime', eventTime)">mdi-close-circle</v-icon>
              </template>
              Delete
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
  import Utils from '@/services/Utils.js';

  export default {

    props: {
      eventTimes: null,
    },

    data () {
      return {
      }
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },

    methods: {

      percent(event) {
        if (!event.soldTickets) {
          return 0;
        }
        return  (100 * event.soldTickets / (event.soldTickets + event.remainingTickets)).toFixed(0);
      }, 

      selectItem(eventTime) {
        this.$router.push("/Dashboard/EventTime/" + eventTime.id)
      },
    }
  }
</script>
